import React from "react"
import { Link } from "gatsby"
import { FaChartArea, FaPlug } from "react-icons/fa"
import {
  formatInfrastructure,
  renderDeposit,
  renderPrice,
} from "../utils/helpers"

const Lot = ({ item, auction, id }) => (
  <div className="col mb-4">
    <div className="card my-2">
      <div className="card-body">
        <h4 className="card-title">
          <Link to={`/auction/${auction.id}/lot/${id}`}>
            {id + ". " + item.Address}
          </Link>
        </h4>
        <p
          className="card-text mb-2"
          style={{
            fontSize: ".9em",
            fontWeight: "400",
            color: "#747373",
          }}
        >
          {item.number}
        </p>

        <p
          className="font-weight-lighter w-100 mb-0"
          style={{ color: "#747373", fontSize: ".8em" }}
        >
          Начальная цена:
        </p>
        <p className="w-100 mb-1" style={{ fontSize: "1.4rem" }}>
          {renderPrice(item.StartPrice)}
        </p>
        <p
          className="font-weight-lighter w-100 mb-0"
          style={{ color: "#747373", fontSize: ".8em" }}
        >
          Депозит:
        </p>
        <p className="w-100 mb-1">
          {renderDeposit(item.StartPrice, item.Deposit)}
        </p>
        <p
          className="font-weight-lighter w-100 mb-0"
          style={{ color: "#747373", fontSize: ".8em" }}
        >
          Расходы на документацию:
        </p>
        <p className="w-100 mb-0">{renderPrice(item.Documents)}</p>
        <div className="d-flex justify-content-between mt-2">
          <div className="align-self-end">
            <p className="pb-2 mb-2">
              <FaChartArea className="mr-2" />
              {item.Area} га
            </p>
            <p className="pb-2">
              <FaPlug className="mr-2" />
              {formatInfrastructure(item.Infrastructure)}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Lot
