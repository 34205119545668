import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { IconContext } from "react-icons"
import { FaMapMarker, FaDollarSign, FaChartArea, FaPlug } from "react-icons/fa"
import Lot from "../components/lot"
import { Map, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {
  formatDate,
  formatInfrastructure,
  transliterateTextToUrl,
} from "../utils/helpers"
import marked from "marked"
import { Link } from "gatsby"

export default ({ pageContext }) => {
  const item = pageContext.auction
  const position = [53.705, 27.5]
  const zoom = 8
  const options = {
    center: position,
    defaultBaseMap: "OpenStreetMap",
    zoom: zoom,
    height: 300,
  }
  const bounds = []
  const markers = item.Lots.map((lot, key) => {
    if (
      typeof window !== "undefined" &&
      lot.Location &&
      lot.Location.Latitude &&
      lot.Location.Longitude
    ) {
      bounds.push([lot.Location.Latitude, lot.Location.Longitude])
    }
    return (
      lot.Location &&
      lot.Location.Latitude && (
        <Marker
          key={lot.id}
          position={[lot.Location.Latitude, lot.Location.Longitude]}
        >
          <Popup>
            <AniLink
              swipe
              direction="left"
              to={`/auction/${item.id}/lot/${key}`}
            >
              {key + ". " + lot.Address}
            </AniLink>
            <p>
              <FaDollarSign className="mr-1" />
              {lot.StartPrice}
            </p>
            <p>
              <FaChartArea className="mr-1" />
              {lot.Area}
            </p>
            <p>
              <FaPlug className="mr-1" />
              {formatInfrastructure(lot.Infrastructure)}
            </p>
          </Popup>
        </Marker>
      )
    )
  })
  if (bounds.length) {
    options["bounds"] = bounds
  }

  return (
    <Layout>
      <SEO title={item.Title + " " + formatDate(item.Date)} />
      <div className="row mb-4">
        <div className="card p-0 my-2 border-0 w-100">
          <div className="card-body p-md-0">
            <h2 className="card-title mx-md-0">{item.Title}</h2>
            <small className="text-muted pr-3">{formatDate(item.Date)}</small>
            <IconContext.Provider value={{ color: "#333" }}>
              <FaMapMarker />
            </IconContext.Provider>
            <small className="text-muted pl-1">
              {item.Region && (
                <Link
                  className="text-muted"
                  to={`/region/` + transliterateTextToUrl(item.Region.Name)}
                >
                  {item.Region.Name}
                </Link>
              )}
            </small>
            <div className="map-wrapper map-wrapper mt-2">
              {typeof window !== "undefined" && (
                <Map style={{ height: "300px", width: "100%" }} {...options}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {markers}
                </Map>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
          {item.Lots &&
            item.Lots.map((lot, key) => (
              <Lot key={lot.id} id={key + 1} item={lot} auction={item} />
            ))}
          {!item.Lots && <div>No results yet...</div>}
        </div>
      </div>
      <div className="container">
        <div className="row">
          {item.Conditions && (
            <div
              className="col"
              dangerouslySetInnerHTML={{ __html: marked(item.Conditions) }}
            ></div>
          )}
        </div>
      </div>
    </Layout>
  )
}
